import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useFetchFlowBlocks from '../../api/FetchFlowBlocks';
import { setInitialFlowData } from '../../redux/student/studentSlice';

const FlowBlocksChange = () => {
    const [queryParams, setQueryParams] = useState( {} );
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { client_name, grade, user_type, test_type, testId } = useParams();

    const searchParams = new URLSearchParams( location.search );
    const userEmail = searchParams.get( 'user_email' );
    const userName = searchParams.get( 'user_name' );
    const studentRollNumber = searchParams.get( 'user_id' );
    const currentFlowBlock = searchParams.get( 'currentBlock' );

    useEffect( () => {
        if ( !currentFlowBlock ) return;
        sessionStorage.setItem( 'currentFlowName', currentFlowBlock );
    }, [currentFlowBlock] )

    const currentFlowName = useSelector( ( state ) => state.flow.currentFlowName )
    const formConfigRedux = useSelector( state => state.formConfiguration );

    const formConfig = formConfigRedux?.form_data;
    const template_id = formConfig?.pdf_template_name;

    const userEmailLocalStorage = localStorage.getItem( 'user_email' ) || userEmail || "";
    const userNameLocalStorage = localStorage.getItem( 'studentName' ) || userName || "";
    const userId = localStorage.getItem( 'user_id' ) || studentRollNumber || "";

    const { response: flowBlocks, isLoading: flowLoading, FetchFlowBlocks } = useFetchFlowBlocks( testId );

    useEffect( () => {
        if ( flowBlocks && !currentFlowName ) {
            //console.log( 'flowBlocks', flowBlocks );
            dispatch( setInitialFlowData( flowBlocks ) );
        }
    }, [flowBlocks] );

    //console.log( 'currentFlowName', currentFlowName );

    useEffect( () => {
        const params = {};
        for ( let [key, value] of searchParams.entries() ) {
            if ( key !== 'currentBlock' ) { // Exclude 'currentBlock' from being added to params
                params[key] = value;
            }
        }

        if ( Object.keys( params ).length > 0 ) {
            localStorage.setItem( 'test_query_params', JSON.stringify( params ) );
            setQueryParams( params );
        }
    }, [location.search] );


    const constructUrlWithParams = ( baseUrl ) => {
        const queryString = new URLSearchParams( queryParams ).toString();

        if ( queryString ) {
            return `${ baseUrl }?${ queryString }`;
        }

        const storedParams = JSON.parse( localStorage.getItem( 'test_query_params' ) ) || {};
        const storedQueryString = new URLSearchParams( storedParams ).toString();

        return `${ baseUrl }${ storedQueryString ? `?${ storedQueryString }` : '' }`;
    };

    useEffect( () => {
        if ( !flowBlocks ) return;

        switch ( currentFlowName ) {
            case "questionnaire_view":
                navigate( constructUrlWithParams( `/${ client_name }/${ test_type }/${ testId }` ), { replace: true } );
                break;
            case "email_verification":
                navigate( constructUrlWithParams( `/${ client_name }/${ test_type }/${ testId }/email_verification` ), { replace: true } );
                break;
            case "sign_up_view":
                navigate( constructUrlWithParams( `/${ client_name }/${ test_type }/signup` ), { replace: true } );
                break;
            case "coupon_code_verification":
                navigate( constructUrlWithParams( `/${ client_name }/verification/coupon_code` ), { replace: true } );
                break;
            case "instruction_view":
                navigate( constructUrlWithParams( `/${ client_name }/${ test_type }/${ testId }/instructions` ), { replace: true } );
                break;
            case "test_selection_view":
                navigate( constructUrlWithParams( `/${ client_name }/test/selection/?user_email=${ userEmailLocalStorage }&user_name=${ userNameLocalStorage }&user_id=${ userId }` ), { replace: true } );
                break;
            case "thank_you_page_view":
                if ( template_id ) {
                    navigate( constructUrlWithParams( `/${ client_name }/thank_you` ), { replace: true } );
                } else if ( userNameLocalStorage && userEmailLocalStorage ) {
                    navigate( constructUrlWithParams( `/${ client_name }/thank_you/?user_email=${ userEmailLocalStorage }&user_name=${ userNameLocalStorage }&user_id=${ userId }` ), { replace: true } );
                } else {
                    navigate( constructUrlWithParams( `/${ client_name }/thank_you` ), { replace: true } );
                }
                break;
            default:
                break;
        }
    }, [flowBlocks, currentFlowName] );

    return <div></div>;
};

export default FlowBlocksChange;
